
import Chart from "chart.js";
import { InjectReactive, Prop, Watch } from "vue-property-decorator";
import { SnapshotBalance } from "../../../shared/models/snapshot-dto";
import { AccountService } from "../services/account";
import { NetFlowVue } from "./NetFlowBaseVue";
import { Formatter } from '../../../shared/utils/formatter'

export default class SnapshotView extends NetFlowVue {
  @Prop(String) accountId!: string;

  futureTransactions: SnapshotBalanceWithOverride[] = [];
  pastTransactions: SnapshotBalance[] = [];
  accountName: string = "";

  // vertical line to mark "Today" https://stackoverflow.com/a/43092029/75672

  @InjectReactive() isInit!: boolean;
  
  @Watch("isInit", { immediate: true }) onIsInitChanged() {
    if (this.isInit) { 
      this.loadData();
    }
  }

  loadData() {
      new AccountService().getAccountSnapshot(this.getAccessToken(), this.accountId).then((data) => {
        this.futureTransactions = data.balances.filter(b=>b.future).map(s=> new SnapshotBalanceWithOverride (s));
        this.pastTransactions = data.balances.filter(b=>!b.future);
        this.accountName = data.account.official_name ?? data.account.name;
        this.loadChart();
    });
  }
  loadChart() {
      this.createChart(this.accountName, this.pastTransactions, this.futureTransactions.map(s=>s.snapshotBalance as SnapshotBalance));
  }

  updateOverrides() {
    console.log('update overrides clicked');
    if(this.futureTransactions.length > 0) {
      // TODO remove this logic from backend
      let currentBalance = this.futureTransactions[0].snapshotBalance?.balance ?? 0;
      for (let i = 1; i< this.futureTransactions.length; i++) {
        currentBalance += +this.futureTransactions[i].override;
        (this.futureTransactions[i].snapshotBalance as any).balance = currentBalance;
      }

      this.loadChart();
    }
  }

  createChart(name: string, current: SnapshotBalance[], future: SnapshotBalance[]) {
    const cavnas = document.getElementById("myChart") as HTMLCanvasElement;
    const formatter = Formatter;

    new Chart(cavnas, {
      type: "line",
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              type: "time",
              time: {
                unit: "day",
              },
            },
          ],
        },
        tooltips: {
          displayColors: false,
          callbacks: {
            title: function(tooltipItems,data) {
              return new Date(tooltipItems[0].label as string).toDateString();
            },
            label: function (tooltipItems, data) {
              const item = tooltipItems.datasetIndex === 0?  current[tooltipItems.index as number]: future[tooltipItems.index as number];
              return [
                item.notes,
                `amount: ${formatter.currencyUSD(item.transactionAmount)}`,
                `balance: ${formatter.currencyUSD(item.balance)}`,
              ];
            },
          },
        },
      },
      data: {
        datasets: [
          {
            steppedLine: true,
            fill: false,
            borderDash: [5, 0],
            borderColor: "rgba(255,64,64,1)",
            label: name,
            data: current.map((d) => { return { x: d.date, y: d.balance };}),
          },
          {
            steppedLine: true,
            fill: false,
            borderDash: [5, 5],
            borderColor: "rgba(255,64,64,1)",
            label: "predicted",
            data: future.map((d) => { return { x: d.date, y: d.balance };}),
          },
        ],
      },
    });
  }
}

class SnapshotBalanceWithOverride{
  override: number = 0;
  snapshotBalance? :SnapshotBalance;
  constructor(snapshotBalance: SnapshotBalance) {
    this.snapshotBalance = snapshotBalance;
    this.override = snapshotBalance.transactionAmount;
  }
}
